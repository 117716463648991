import $axios from '@/utils/request'

// 获取竞赛管理列表
export function getRaceContestList (data) {
  const url = '/questionlibrary/getRaceContestList'
  return $axios.fPost(url, data)
}

// 竞赛详情
export function getContextInfo (data) {
  const url = '/questionlibrary/getContextInfo'
  return $axios.fGet(url, data)
}


// 新增竞赛
export function addQuestionLibrary (data) {
  const url = '/questionlibrary/addQuestionLibrary'
  return $axios.fPost(url, data)
}

// 编辑竞赛
export function updateQuestionLibrary (data) {
  const url = '/questionlibrary/updateQuestionLibrary'
  return $axios.fPost(url, data)
}

// 发布停用竞赛
export function updateQuestionState (data) {
  const url = '/questionlibrary/updateQuestionState'
  return $axios.fPost(url, data)
}

// 竞赛记录
export function getSubmitHistory (data) {
  const url = '/question/getSubmitHistory'
  return $axios.fPost(url, data)
}

// 查询题库的题目
export function getQuestionByLibraryId (data) {
  const url = '/questionlibrary/getQuestionByLibraryId'
  return $axios.fGet(url, data)
}

// 删除题库关联题目
export function deleteContestProblem (data) {
  const url = '/questionlibrary/deleteContestProblem'
  return $axios.fGet(url, data)
}

// 获取题库成员
export function getContestUserList (data) {
  const url = '/contest/user/getContestUserList'
  return $axios.fPost(url, data)
}

// 增加题库成员(支持批量)
export function batchAddUser (data) {
  const url = '/contest/user/batchAddUser'
  return $axios.fPost(url, data)
}

// 删除题库成员
export function deleteUser (data) {
  const url = '/contest/user/delete'
  return $axios.fGet(url, data)
}

// 审核题库成员成功
export function verifySuccess (data) {
  const url = '/contest/user/verifySuccess'
  return $axios.fPost(url, data)
}

// 审核题库成员失败
export function verifyFailed (data) {
  const url = '/contest/user/verifyFailed'
  return $axios.fPost(url, data)
}

// 题库题目排序
export function sortQuestionLibrary (data) {
  const url = '/questionlibrary/sortQuestionLibrary'
  return $axios.fPost(url, data)
}


//管理员添加
export function addManager (data) {
  const url = '/contest/manager/addManager'
  return $axios.fPost(url, data)
}

//管理员删除
export function deleteManager (data) {
  const url = '/contest/manager/delete'
  return $axios.fGet(url, data)
}

//管理员列表
export function getContestManagerList (data) {
  const url = '/contest/manager/getContestManagerList'
  return $axios.fGet(url, data)
}

//竞赛排行列表
export function getRankingList (data) {
  const url = '/questionlibrary/getRankingList'
  return $axios.fGet(url, data)
}



